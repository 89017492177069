@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  flex: 1;
}

.content {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.content2,
.modalContent{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 0 auto 0 auto;
}

.greeting {
  /* Font */
  color: var(--colorWhite);
  font-family: 'Quicksand';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 477.273% */
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  color: var(--colorWhite);
  text-align: center;
  font-family: 'Quicksand';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;


  /* Position component */
  width: initial;
  margin: 4px 0 0px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  color: var(--colorWhite);
  text-align: center;
  font-family: 'Source Serif 4';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  /* Font */
  color: var(--colorWhite);
  text-align: center;
  font-family: 'Quicksand';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;

  /* Layout */
  margin-top: 0;
  margin-bottom: 0px;
}
.currentPageLink {
  color: var(--colorWhite);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
  border: 1px solid #fff;
  margin: 0 auto;
  max-width: 190px;
  color: #fff;
  text-align: center;
  font-family: 'Source Serif 4';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  min-height: 50px;
  padding: 10px 0 6px 0;
}

.authenticationGreeting {
  /* Font */
  color: var(--colorWhite);
  font-family: 'Quicksand';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 477.273% */

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--colorWhite);
  text-align: center;
  font-family: 'Source Serif 4';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
}
.loginLink {
  composes: buttonPrimary from global;
  text-decoration: none;
  white-space: nowrap;
  color: var(--Forteh---Prime---Black, #2f2d2b);
  background: var(--colorWhite);
  text-align: center;
  font-family: 'Source Serif 4';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.72px;
  margin: 36px auto 19px;
  max-width: 190px;
  min-height: 50px;
  padding: 10px 0 6px 0;
}

.currentPage {
  color: var(--colorWhite);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}

.logoLink {
  margin: 0 auto;
  & img{
    height: 46px;
  }
}

.menuLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px !important;
  margin-bottom: 36px;
  & a {
    color: #fff;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.18px;
  }
}

.socialMediaLinks{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 17px;
  margin: 20px 0;
  flex: 1;
}